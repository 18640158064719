const SharedHosting = [
  {
    path: "",
    name: "shared-hosting",
    meta: {
      breadcrumb: "SHARED_HOSTING",
    },
    component: () =>
      import(
        /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SharedHostingList.vue"
      ),
  },
  {
    path: "/shared-hosting/:sharedHostId",
    name: "shared-host",
    meta: {
      sidebar: "SHARED_HOST",
    },
    component: () =>
      import(
        /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SharedHostingDetail.vue"
      ),
    props: (route) => ({ id: Number(route.params.sharedHostId) }),
    redirect: {
      name: "shared-host-overview",
    },
    children: [
      {
        path: "overview",
        name: "shared-host-overview",
        meta: {
          breadcrumb: "SHARED_HOST",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SharedHostOverview.vue"
          ),
      },
      {
        path: "safe-harbor",
        name: "shared-host-safe-harbor",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR",
          sidebar: "SHARED_HOST_SAFE_HARBOR",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/SafeHarborDetail.vue"
          ),
        redirect: {
          name: "shared-host-safe-harbor-malware-scans",
        },
        children: [
          {
            path: "malware-scans",
            name: "shared-host-safe-harbor-malware-scans",
            meta: {
              breadcrumb: "SHARED_HOST_SAFE_HARBOR_MALWARE_SCANS",
            },
            component: () =>
              import(
                /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/MalwareScans.vue"
              ),
          },
          {
            path: "protection",
            name: "shared-host-safe-harbor-threat-protection",
            meta: {
              breadcrumb: "SHARED_HOST_SAFE_HARBOR_THREAT_PROTECTION",
            },
            component: () =>
              import(
                /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/ThreatProtection.vue"
              ),
          },
          {
            path: "staging-environments",
            name: "shared-host-safe-harbor-staging-environments",
            meta: {
              breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENTS",
            },
            component: () =>
              import(
                /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironments.vue"
              ),
          },
          {
            path: "patches",
            name: "shared-host-safe-harbor-magento-patches",
            meta: {
              breadcrumb: "SHARED_HOST_SAFE_HARBOR_MAGENTO_PATCHES",
            },
            component: () =>
              import(
                /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/MagentoPatches.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/shared-hosting/:sharedHostId/safe-harbor/staging-environments/:stagingEnvironmentId",
    name: "shared-host-safe-harbor-staging-environment",
    meta: {
      breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT",
      sidebar: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT",
    },
    component: () =>
      import(
        /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment"
      ),
    props: (route) => ({
      serviceId: Number(route.params.sharedHostId),
      stagingEnvironmentId: Number(route.params.stagingEnvironmentId),
    }),
    redirect: {
      name: "shared-host-safe-harbor-staging-environment-performance",
    },
    children: [
      {
        path: "performance",
        name: "shared-host-safe-harbor-staging-environment-performance",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_PERFORMANCE",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Analytics"
          ),
      },
      {
        path: "domains",
        name: "shared-host-safe-harbor-staging-environment-domains",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_DOMAINS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Domains"
          ),
      },
      {
        path: "settings",
        name: "shared-host-safe-harbor-staging-environment-settings",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SETTINGS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Settings"
          ),
      },
      {
        path: "environment",
        name: "shared-host-safe-harbor-staging-environment-environment",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_ENVIRONMENT",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Management"
          ),
      },
      {
        path: "ssl",
        name: "shared-host-safe-harbor-staging-environment-ssl",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SSL",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Ssl"
          ),
      },
      {
        path: "Logs",
        name: "shared-host-safe-harbor-staging-environment-logs",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_LOGS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Logs"
          ),
      },
      {
        path: "databases",
        name: "shared-host-safe-harbor-staging-environment-databases",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_DATABASES",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Databases"
          ),
      },
      {
        path: "scheduled-tasks",
        name: "shared-host-safe-harbor-staging-environment-scheduled-tasks",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SCHEDULED_TASKS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/ScheduledTasks"
          ),
      },
      {
        path: "email",
        name: "shared-host-safe-harbor-staging-environment-email",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_EMAIL",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Email"
          ),
      },
      {
        path: "backups",
        name: "shared-host-safe-harbor-staging-environment-backups",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_BACKUPS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Backups"
          ),
        props: () => ({
          sharedHost: true,
        }),
      },
      {
        path: "containers",
        name: "shared-host-safe-harbor-staging-environment-containers",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTAINERS",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Containers"
          ),
      },
      {
        path: "control-panel",
        name: "shared-host-safe-harbor-staging-environment-control-panel",
        meta: {
          breadcrumb: "SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTROL_PANEL",
        },
        component: () =>
          import(
            /* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/ControlPanel"
          ),
      },
    ],
  },
];

export default SharedHosting;
