let magentoLocationExclusions = [];
if (process.env.VUE_APP_MAGENTO_LOCATION_TYPE_EXCLUSIONS) {
  magentoLocationExclusions = process.env.VUE_APP_MAGENTO_LOCATION_TYPE_EXCLUSIONS.split(",");
}

let storeBuilderLabels = [];
if (process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_LABELS) {
  storeBuilderLabels = process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_LABELS.split(",");
}

let storeBuilderUiOverrideLabels = [];
if (process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_UI_OVERRIDE_LABELS) {
  storeBuilderUiOverrideLabels =
    process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_UI_OVERRIDE_LABELS.split(",");
}

let wpQuickstartLabels = [];
if (process.env.VUE_APP_WPQUICKSTART_MEMBERSHIP_LABELS) {
  wpQuickstartLabels = process.env.VUE_APP_WPQUICKSTART_MEMBERSHIP_LABELS.split(",");
}

let wpQuickstartUiOverrideLabels = [];
if (process.env.VUE_APP_WPQUICKSTART_MEMBERSHIP_UI_OVERRIDE_LABELS) {
  wpQuickstartUiOverrideLabels =
    process.env.VUE_APP_WPQUICKSTART_MEMBERSHIP_UI_OVERRIDE_LABELS.split(",");
}

let migrationPriorityIds = [];
if (process.env.VUE_APP_MIGRATION_PRIORITY_PACKAGE_IDS) {
  migrationPriorityIds = process.env.VUE_APP_MIGRATION_PRIORITY_PACKAGE_IDS.split(",").map((id) =>
    Number(id)
  );
}

let locationOverrides = {};
if (process.env.VUE_APP_LOCATION_OVERRIDES) {
  process.env.VUE_APP_LOCATION_OVERRIDES.split(",").forEach((pair) => {
    const [key, value] = pair.split(":");
    locationOverrides[key] = Number(value);
  });
}

export default {
  ROUTES: {
    ACCOUNT_SETTINGS: "account-settings",
    HOME: "dashboard-home",
    MAINTENANCE: "maintenance",
    FORGOT_PASSWORD: "forgot-password",
    SIGNUP: "signup",
    LOGIN: "login",
    REFER_A_FRIEND: "refer-a-friend",
    PLANS: "plan-list",
    PLAN: "plan-detail",
    SITE_STAGING_AND_DEV_LIST: "plan-site-staging-and-dev",
    STOREBUILDER: "storebuilder",
    TICKET: "ticket-detail",
    TICKETS: "tickets",
    VERIFY_TICKET: "verify-ticket",
    WIZARD: {
      WPQUICKSTART: {
        NAME: "configure-wpquickstart",
        PATH: "/configure/wpquickstart",
        STOREBUILDER: {
          NAME: "configure-wpquickstart-storebuilder",
          PATH: "storebuilder",
        },
      },
    },
  },
  AUTH_REMEMBER_ME_TOKEN_KEY: "nexcess_remember_me_token",
  AUTH_TOKEN_KEY: "nexcess_token",
  TABLE_ICON_SIZE: 20,
  TICKETS: {
    DEFAULT_PRIORITY_ID: "2",
    DEPARTMENT_ID_WITH_ADDITIONAL_FIELDS: "1",
  },
  PAYMENT_METHODS: {
    APPLY_CREDIT: "applycredit",
    CREDIT_CARD: "cc",
    PAYPAL: "paypal",
  },
  APP_LOCATION_TYPE_EXCLUSIONS: {
    magento: magentoLocationExclusions,
  },
  CHECKOUT: {
    AFFILIATION: "Portal Checkout",
  },
  CHECKOUT_STEPS: ["checkout-package", "checkout-profile", "checkout-review"],
  CHECKOUT_SURVEY_STEPS: [
    "checkout-package",
    "checkout-profile",
    "checkout-survey",
    "checkout-review",
  ],
  CHECKOUT_SURVEY_UI_OVERRIDE_STEPS: [
    "checkout-package",
    "checkout-profile",
    "checkout-survey",
    "checkout-review",
  ],
  COUNTRIES: {
    US: "United States",
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia And Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, The Democratic Republic Of The",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte D'ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard And Mcdonald Islands",
    VA: "Holy See (vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (islamic Republic Of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic Of",
    KR: "Korea, Republic Of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, The Former Yugoslav Republic Of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova, Republic Of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And The Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia (slovak Republic)",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And The South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic Of",
    TH: "Thailand",
    TL: "Timor-leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic Of",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis And Futuna Islands",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  STATES: {
    AU: {
      OT: "Other State/Territory",
      NSW: "New South Wales",
      QLD: "Queensland",
      SA: "South Australia",
      TAS: "Tasmania",
      VIC: "Victoria",
      WA: "Western Australia",
      ACT: "Australian Capital Territory",
      NT: "Northern Territory",
    },
    CA: {
      OT: "Other State",
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NL: "Newfoundland And Labrador",
      NB: "New Brunswick",
      NT: "Northwest Territories",
      NS: "Nova Scotia",
      NU: "Nunavut",
      ON: "Ontario",
      PE: "Prince Edward Island",
      QC: "Quebec",
      SK: "Saskatchewan",
      YT: "Yukon",
    },
    US: {
      OT: "Other State",
      AL: "Alabama",
      AK: "Alaska",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
  },
  COUNTRY_CITY_TYPE: {
    AU: "suburb",
    CA: "city",
    DEFAULT: "town-city",
    GB: "city",
    IN: "city",
    US: "city",
  },
  COUNTRY_DIVISION_TYPE: {
    AU: "state-territory",
    CA: "province",
    DEFAULT: "state-province-region",
    GB: "none",
    US: "state",
  },
  COUNTRY_POSTAL_TYPE: {
    AU: "post-code",
    CA: "postal-code",
    DEFAULT: "postal-code",
    GB: "post-code",
    IN: "pin-code",
    US: "zip",
  },
  CARTSTACK_COUNTRY_WHITELIST: [
    "AR",
    "AU",
    "BO",
    "BR",
    "CA",
    "CL",
    "CO",
    "CR",
    "DZ",
    "EC",
    "GY",
    "HK",
    "ID",
    "IL",
    "IN",
    "JP",
    "KR",
    "MX",
    "NZ",
    "PE",
    "PH",
    "PK",
    "PY",
    "RU",
    "SA",
    "SR",
    "TH",
    "TN",
    "TR",
    "UA",
    "US",
    "UY",
    "VE",
    "VI",
    "VN",
    "ZA",
  ],
  LINKS: {
    // link to magento docs regarding its elasticsearch dependency
    ELASTICSEARCH_MAGENTO_DOCS:
      "https://devdocs.magento.com/guides/v2.3/config-guide/elasticsearch/configure-magento.html",
    IMPACT: "https://app.impact.com/bla/Liquid-Web-LLC/login.user",
    NEXCESS: {
      BLOG: {
        NX_BECOMING_LW: "https://www.nexcess.net/blog/exciting-news-for-nexcess",
      },
      KB: {
        ADMIN_CREDENTIALS:
          "https://www.nexcess.net/help/how-to-view-your-admin-credentials-in-the-portal-for-your-managed-wordpress-or-managed-woocommerce-new-install-site",
        ALTERNATE_PORTAL: "https://www.nexcess.net/help/nexcess-portal-navigation-improvements",
        BENEFITS_OF_CNAME: "https://www.nexcess.net/help/benefits-of-cname-target",
        DESIGN_SERVICES:
          "https://www.nexcess.net/help/introducing-design-services-for-wordpress-and-woocommerce-websites",
        HELP: "https://www.nexcess.net/help",
        HOW_NEXCESS_LIMITS_BAD_BOTS: "https://www.nexcess.net/help/how-nexcess-limits-bad-bots",
        HOW_TO_PATCH_YOUR_MAGENTO_1_STORE:
          "https://www.nexcess.net/help/how-to-patch-your-magento-1-store",
        HOW_TO_PREVIEW_A_WEBSITE_BEFORE_DNS_UPDATE_IS_COMPLETE:
          "https://www.nexcess.net/help/how-to-preview-a-website-before-dns-update-is-complete/",
        HOW_TO_SEND_SECURE_DATA_TO_NEXCESS_SUPPORT:
          "https://www.nexcess.net/help/how-to-send-secure-data-to-nexcess-support",
        HOW_TO_USE_VISUAL_COMPARISON_TOOL:
          "https://www.nexcess.net/help/how-to-use-visual-comparison-tool",
        LOCATING_YOUR_FTPS_CREDENTIALS_FOR_NEXCESS_CLOUD:
          "https://www.nexcess.net/help/locating-your-ftps-credentials-for-nexcess-cloud",
        MIGRATE_DNS:
          "https://www.nexcess.net/help/how-to-point-your-domain-name-to-nexcess-dns-services",
        MIGRATION_PROCESS: "https://www.nexcess.net/help/the-migration-process-at-nexcess",
        PERFORMANCE_SHIELD: "http://nexcess.net/help/introducing-performance-shield",
        PRE_MIGRATION_CHECKLIST: "https://www.nexcess.net/help/pre-migration-checklist",
        PORTAL_GUIDE: "https://www.nexcess.net/help/nexcess-portal-guide",
        REAL_TIME_IP_BLACKLIST: "https://www.nexcess.net/help/real-time-ip-blacklist",
        REFER_A_FRIEND: "https://www.nexcess.net/help/refer-a-friend",
        REFER_A_FRIEND_GIVEAWAY_TERMS_AND_CONDITIONS:
          "https://www.nexcess.net/help/refer-a-friend-giveaway-terms-and-conditions",
        SEARCH: "https://www.nexcess.net/help-search",
        SITE_KEEP:
          "https://www.nexcess.net/help/introducing-sitekeep-for-wordpress-and-woocommerce-websites",
        USING_STENCIL_SITES_IN_MANAGED_WORDPRESS_AND_MANAGED_WOOCOMMERCE_HOSTING:
          "https://www.nexcess.net/help/using-stencil-sites-in-managed-wordpress-and-managed-woocommerce-hosting",
        WHAT_IS_A_WEB_APPLICATION_FIREWALL:
          "https://www.nexcess.net/help/what-is-a-web-application-firewall",
        WHAT_IS_ADVANCED_AUTO_SCALING: "https://www.nexcess.net/help/what-is-advanced-auto-scaling",
        WHAT_IS_NEXCESS_CLOUD_AUTO_SCALING:
          "https://www.nexcess.net/help/what-is-nexcess-cloud-auto-scaling",
        WHAT_IS_WHOIS: "https://www.nexcess.net/help/what-is-whois",
        MWX_STAGING_SITE_CREATE_KB:
          "https://www.nexcess.net/help/setting-up-a-staging-site-in-managed-wordpress-and-managed-woocommerce-hosting/",
        DEVELOPMENT_ENVIRONMENT_KB:
          "https://www.nexcess.net/help/how-to-create-dev-sites-in-nexcess-cloud/",
      },
      DESIGN_SERVICES: "https://www.nexcess.net/services/wordpress-website-design/",
      PARTNER_LEARN_MORE: "https://www.nexcess.net/partners",
      PRIVACY_POLICY: "https://www.nexcess.net/policies/privacy-policy",
      TOS: "https://www.nexcess.net/policies/terms-of-service",
      CONTACT: "https://www.nexcess.net/contact/",
    },
    // link to magento docs regarding its rabbitmq dependency
    RABBITMQ_MAGENTO_DOCS:
      "https://devdocs.magento.com/guides/v2.3/install-gde/prereq/install-rabbitmq.html#connect-rabbitmq-to-magento-open-source-or-magento-commerce",
  },
  DEFAULT_VERSION_CHECKER_FREQUENCY: 5,
  DEFAULT_MAINTENANCE_CHECKER_FREQUENCY: 5,
  DEFAULT_ADV_AUTO_SCALE_LOCATIONS: ["us-midwest-1", "us-west-1", "uk-south-2"],
  PACKAGE_SURVEYS: {
    WPQUICKSTART: {
      STOREBUILDER: {
        LABELS: storeBuilderLabels,
        UI_OVERRIDE_LABELS: storeBuilderUiOverrideLabels,
      },
      MEMBERSHIP: {
        LABELS: wpQuickstartLabels,
        UI_OVERRIDE_LABELS: wpQuickstartUiOverrideLabels,
      },
    },
  },
  MIGRATION_PRIORITY_IDS: migrationPriorityIds,
  BANK_ACCOUNT_TYPE_OPTIONS: ["Checking", "Savings"],
  MODES: {
    MAINTENANCE: -1,
    STANDARD: 0,
  },
  SUPPORT: {
    EMAIL: "support@nexcess.net",
    PHONE: {
      US: "1-866-639-2377",
      UK: "0-808-196-5715",
      AU: "1-800-765-472",
      INTERNATIONAL: "1-313-279-0722",
    },
    STATUS_PAGE: "https://status.nexcess.net/",
  },
  SALES: {
    EMAIL: "sales@nexcess.net",
  },
  BILLING: {
    EMAIL: "billing@nexcess.net",
  },
  RESTORE_STATUS: {
    PENDING: "pending",
    STARTED: "started",
    FAILED: "failed",
    COMPLETED: "completed",
  },
  BACKUP_DBS_LOADING_MESSAGES: [
    { message: "Fetching database backup details", delay: 15000 },
    { message: "Still working, please wait", delay: 30000 },
    { message: "This can sometimes take several minutes", delay: 60000 },
  ],
  MAGENTO_ELASTICSEARCH_ADDON_ID: process.env.VUE_APP_MAGENTO_ELASTICSEARCH_ADDON_ID,
  LOCATION_OVERRIDES: locationOverrides,
  DEFAULT_LOCATION_ID: process.env.VUE_APP_DEFAULT_LOCATION_ID,
  DEFAULT_LOCATION_CODE: process.env.VUE_APP_DEFAULT_LOCATION_CODE,
  GOOGLE_OPTIMIZE: {
    CHECKOUT_VOLTRON: {
      CONTAINER_ID: process.env.VUE_APP_GOOGLE_OPTIMIZE_CHECKOUT_VOLTRON_CONTAINER_ID,
      EXPERIMENT_ID: process.env.VUE_APP_GOOGLE_OPTIMIZE_CHECKOUT_VOLTRON_EXPERIMENT_ID,
    },
  },
  DOMAIN_REGISTRATION_URL: "https://www.nexcess.net/domain-registration/",
  DOMAIN_MANAGEMENT_URL: "https://manage.opensrs.net/",
  IRPIDS: Object.fromEntries(process.env.VUE_APP_IRPIDS.split(",").map((item) => item.split(":"))),
  ELASTIC_SEARCH_ADDON_ID: parseInt(process.env.VUE_APP_MAGENTO_ELASTICSEARCH_ADDON_ID),
  MAPP_API: process.env.VUE_APP_MAPP_API,
  REGION_MAP: {
    "us-midwest-1": "US East",
    "us-midwest-2": "US East",
    "au-south-1": "Asia Pacific",
    "uk-south-2": "Europe",
    "us-west-1": "US West",
    "us-west-2": "US West",
    "nl-west-1": "Europe",
    "us-south-1": "US South",
  },
  RESTRICT_APPLICATION_SUPPORT: ["mwp.lite"],
  RESTRICT_CLOUD_AUTOSCALING: ["mwp.lite"],
  RESTRICT_RESIZE: ["mwp.lite", "mwp.vip"],
  RESTRICT_VISUAL_COMPARISON: ["mwp.lite"],
  RESTRICT_MONTHLY: ["mwp.lite"],
};
