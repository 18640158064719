<template>
  <FocusTrap :active="true" fallback-focus="#modal-dialog">
    <div
      class="modal fade show"
      id="exampleModalLive"
      tabindex="-1"
      role="dialog"
      :aria-labelledby="idCalc"
      aria-modal="true"
      style="display: block"
    >
      <div id="modal-dialog" class="modal-dialog" :class="modalSize">
        <div class="modal-content">
          <div class="modal-container">
            <div class="modal-header tw-flex tw-w-full">
              <slot name="header">
                <h5 class="modal-title" :id="idCalc" v-if="title">{{ title }}</h5>
              </slot>
              <div class="close-container tw-flex-grow">
                <IconButton
                  v-if="!hideClose"
                  :icon="{
                    default: { icon: 'fa-regular fa-xmark' },
                    hover: { icon: 'fa-solid fa-xmark' },
                  }"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="close"
                  :testId="closeIconTestId"
                />
              </div>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer" v-if="!hideFooter">
              <slot name="footer">
                <Btn class="me-2" variant="secondary" data-dismiss="modal" @click="close">
                  {{ closeName }}
                </Btn>
                <Btn variant="primary" @click="confirm">{{ confirmName }}</Btn>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import Btn from "@/components/display/Btn";
import { FocusTrap } from "focus-trap-vue";
import IconButton from "@/components/display/IconButton.vue";
import uniqueId from "lodash-es/uniqueId";

export default {
  name: "Modal",
  props: {
    title: String,
    dismissible: { type: Boolean, default: true },
    data: Object,
    id: String,
    closeName: { type: String, default: "Close" },
    confirmName: { type: String, default: "Confirm" },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
  },
  components: {
    Btn,
    IconButton,
    FocusTrap,
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const sizeList = {
      small: "modal-sm",
      medium: "",
      large: "modal-lg",
      xlarge: "modal-xl",
    };

    const idCalc = computed(() => props.id || uniqueId("input-"));

    const closeIconTestId = computed(() =>
      props.title
        ? `${props.title.replaceAll(" ", "-").toLowerCase()}-modal-close-icon-btn`
        : "modal-close-icon-btn"
    );

    const close = () => emit("close");
    const confirm = () => emit("confirm");

    const modalSize = computed(() => sizeList[props.size]);

    onMounted(() => {
      document.body.classList.add("modal-open");
    });

    onUnmounted(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      close,
      confirm,
      idCalc,
      closeIconTestId,
      modalSize,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";
.modal-content {
  @apply tw-bg-background-medium tw-border tw-border-border;

  .close-container {
    display: flex;
    justify-content: flex-end;
    .close {
      opacity: 100;
    }
    min-height: 40px;
  }
  .modal-container {
    padding: 0 2.25rem 2.25rem 2.25rem;
    .modal-header {
      border: none;
      padding: 0 0 0.5rem 0;
      .modal-title {
        font-size: 20px;
        @apply tw-text-text;
      }
    }
    .modal-header {
      padding: 1rem 0 1.25rem 0;
    }
    .modal-body {
      font-size: 0.875rem;
      padding: 0;
    }
    .modal-footer {
      border: none;
      padding: 2rem 0 0 0;
      button {
        margin: 0;
      }
    }
  }
}
</style>
