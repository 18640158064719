<template>
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div
      class="toast-timer"
      :class="timerColor"
      v-if="timer"
      :style="{ width: `${(timer / toast.timeout) * 100}%` }"
    />
    <div
      class="toast-header tw-border !tw-border-b-border tw-border-l-4 tw-border-border !tw-bg-background tw-rounded-none !tw-rounded-l-none !tw-rounded-r-none"
      :class="borderLeftColor"
    >
      <div class="tw-flex tw-w-full">
        <font-awesome-icon :icon="`fa-regular ${icon}`" class="tw-text-[20px]" :class="iconClass" />
        <div class="tw-flex-1">
          <div v-if="toast.message" class="me-auto toast-message tw-text-text">
            {{ toast.message }}
          </div>
          <div v-if="toast.template" class="me-auto toast-message">
            <component :is="toast.template" @close="close" />
          </div>
        </div>
        <div>
          <IconButton
            :icon="{
              default: { icon: 'fa-regular fa-xmark' },
              hover: { icon: 'fa-solid fa-xmark' },
            }"
            class="tw-text-[16px] tw-text-icon"
            data-dismiss="toast"
            aria-label="Close"
            @click="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Btn from "@/components/display/Btn";
import IconButton from "@/components/display/IconButton.vue";
import store from "@/store";
import Toast from "@/classes/Toast";

export default {
  name: "Toast",
  props: {
    toast: Toast,
  },
  components: {
    Btn,
    IconButton,
  },
  setup(props) {
    const timer = ref(props.toast?.timeout);
    const interval = ref(null);
    const countdownAmount = 25;
    const BORDER_LEFT_COLOR_MAP = {
      success: "tw-border-l-success-border",
      error: "tw-border-l-danger-border",
      warning: "tw-border-l-warning-border",
      info: "tw-border-l-highlight-border",
    };
    const ICON_CLASS = {
      success: "tw-text-success-icon",
      error: "tw-text-danger-icon",
      warning: "tw-text-warning-icon",
      info: "tw-text-highlight-icon",
    };
    const ICON_MAP = {
      success: "fa-circle-check",
      error: "fa-circle-xmark",
      warning: "fa-triangle-exclamation",
      info: "fa-circle-info",
    };
    const TIMER_COLOR_MAP = {
      success: "tw-bg-success-border",
      error: "tw-bg-danger-border",
      warning: "tw-bg-warning-border",
      info: "tw-bg-highlight-border",
    };

    const borderLeftColor = computed(
      () => BORDER_LEFT_COLOR_MAP[props.toast.variant] || BORDER_LEFT_COLOR_MAP.info
    );
    const icon = computed(() => ICON_MAP[props.toast.variant] || ICON_MAP.info);
    const iconClass = computed(() => ICON_CLASS[props.toast.variant] || ICON_CLASS.info);
    const timerColor = computed(() => TIMER_COLOR_MAP[props.toast.variant] || TIMER_COLOR_MAP.info);

    const close = () => {
      store.dispatch("toasts/removeToast", props.toast);
    };

    onMounted(() => {
      if (timer.value && timer.value > 0) {
        interval.value = setInterval(() => {
          if (timer.value <= 0) {
            close();
          }
          timer.value -= countdownAmount;
        }, countdownAmount);
      }
    });

    onBeforeUnmount(() => {
      if (interval.value) {
        clearInterval(interval.value);
      }
    });

    return {
      borderLeftColor,
      close,
      icon,
      iconClass,
      timer,
      timerColor,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.toast {
  opacity: 1;
  position: relative;
  inline-size: min-content;
  min-width: 350px;
  .toast-header {
    padding: 0.875rem 0.875rem;
    position: relative;
    z-index: 100;
  }
  .toast-timer {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: 150;
  }
  .toast-icon {
    margin-right: 1.2rem;
  }
  .toast-message {
    margin-left: 1rem;
    padding-right: 1rem;
  }
  .close {
    position: absolute;
    right: 14px;
    top: 14px;
  }
  .icon.size-20 {
    width: auto;
  }
}
</style>
